<template>
  <v-container fluid class="pa-0">
    <tv-list :items="allChannels"></tv-list>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import TvList from "@/components/TvList";

export default {
  components: {
    TvList,
  },
  created() {
    this.initData();
  },
  computed: {
    ...mapState({
      allChannels: ({ epg }) => epg.allChannels,
    }),
  },
  methods: {
    ...mapActions("epg", ["getChannels"]),
    initData() {
      this.getChannels();
    },
  },
};
</script>
